<template>
    <div class="_wrap">
        <div class="_auto">


            <div class="_cont">
                <div class="_logo">
                    <img src="@/assets/image/home-img/com_logo.png" alt="八路军驻洛办事处纪念馆-官方网站">
                </div>


                <div class="_r">
                    <div class="_share">
                        <el-popover placement="top" width="180" trigger="hover" style="cursor: pointer;">
                            <div class="_code">
                                <img :src="$IMG_URL + $store.state.webData.wx_qrcode" alt="八路军驻洛办事处纪念馆-官方网站">
                            </div>
                            <img slot="reference" src="@/assets/image/home-img/com_icon_wechat.png"
                                alt="八路军驻洛办事处纪念馆-官方网站">
                        </el-popover>




                        <el-popover placement="top" width="180" trigger="hover" style="cursor: pointer;">
                            <div class="_code">
                                <img :src="$IMG_URL + $store.state.webData.dy_qrcode" alt="八路军驻洛办事处纪念馆-官方网站">
                            </div>
                            <img slot="reference" src="@/assets/image/home-img/com_icon_tiktok.png"
                                alt="八路军驻洛办事处纪念馆-官方网站">
                        </el-popover>

                        <el-popover placement="top" width="180" trigger="hover" style="cursor: pointer;">
                            <div class="_code">
                                <img :src="$IMG_URL + $store.state.webData.ks_qrcode" alt="八路军驻洛办事处纪念馆-官方网站">
                            </div>
                            <img slot="reference" src="@/assets/image/home-img/com_icon_shipinhao.png"
                                alt="八路军驻洛办事处纪念馆-官方网站">
                        </el-popover>







                    </div>


                    <div class="_rx">
                        <h4 class="_impact"> {{ footerData.tel }}</h4>
                        <p>咨询热线</p>
                    </div>
                </div>


            </div>



            <div class="_bottom">

                <div class="_tips" style="text-align: center;margin-bottom: 10px;">
                    为保持最佳浏览请使用360极速模式或谷歌浏览器
                </div>

                <div class="_tt">
                    <div class="_address">
                        <img src="../../assets/image/home-img/com_icon_dingwei.png" alt="八路军驻洛办事处纪念馆-官方网站">
                        <span>地址：{{ footerData.address }}</span>
                    </div>

                    <div class="_email">
                        <img src="../../assets/image/home-img/com_icon_youxiang.png" alt="八路军驻洛办事处纪念馆-官方网站">
                        <span>邮箱：{{ footerData.email }}</span>
                    </div>
                </div>

                <div class="_bb">
                    <i>{{ footerData.copyright }} 版权所有</i> <span @click="goRecord">{{ footerData.record_number }}</span>
                    <div class="_bq">
                    <img src="../../assets/image/home-img/ga.png" alt="">
                    <span @click="goAn">豫公网安备41030202000309号</span>
                </div>
                  
                </div>


                <div class="_pwd">
                    <i>Powed
                        By：{{ footerData.technical_support }}</i>
                </div>

               
              


                <div class="_cc">
                    <p>网站访问量：{{ homeData.request_count }}</p>

                </div>


            </div>


            <div class="_nncm">
                <span id="_ideConac"><a
                        href="https://bszs.conac.cn/sitename?method=show&amp;id=0EA983DC60D12CC7E06310291AAC8EDD"
                        target="_blank"><img id="imgConac" vspace="0" hspace="0" border="0"
                            src="https://dcs.conac.cn/image/blue.png" data-bd-imgshare-binded="1"></a></span>
            </div>





        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            visits: [],
            footerData: {},
            homeData: {},
        }
    },

    watch: {

        '$store.state.homeData': function (newVal, oldVal) {
            console.log('store中的数据已更新');
            this.homeData = this.$store.state.homeData

        },
        '$store.state.webData': function (newVal, oldVal) {
            console.log('store中的数据已更新');
            this.footerData = this.$store.state.webData
          
        }
    },

    mounted() {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://dcs.conac.cn/js/17/253/0000/41514588/CA172530000415145880002.js';

        const ele = document.querySelector("._nncm")
        ele.appendChild(script);
    },
    created() {
        this.footerData = this.$store.state.webData
        this.homeData = this.$store.state.homeData


    },
    methods: {
        goRecord() {
            window.open('https://beian.miit.gov.cn/#/Integrated/index')
        },

        goAn(){
            window.open('https://beian.mps.gov.cn/#/query/webSearch?code=41030202000309')

        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";


._pwd{
    text-align: center;
    margin-bottom: 10px;

    @include respondTo('phone'){
        text-align: left;
    }
}
._bq{
    display: flex;
    align-items: center;
    img{
        width: 20px;
        height: 20px;
    }
}

._wrap
{
    width: 100%;
    height: 300px;
    background-image: url("../../assets/image/home-img/com_bg_footer.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

._auto
{
    width: 75%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    color: white;
}

._cont
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;

    ._r
    {
        display: flex;

        ._share
        {
            padding-right: 30px;
            border-right: 1px solid #C27272;
            display: flex;
            justify-content: space-between;
            width: 270px;
            height: 54px;

        }

        ._rx
        {
            margin-left: 30px;

            ._impact
            {
                font-size: 30px;
            }
        }

    }
}

._bottom
{
    margin-top: 20px;

    ._tt
    {
        display: flex;
        justify-content: center;

        ._address
        {
            margin-right: 40px;
            display: flex;
            align-items: center;
        }

        ._email
        {
            display: flex;
            align-items: center;
        }

        img
        {
            margin-right: 5px;
        }
    }

    ._bb
    {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        span
        {
            text-decoration: underline;
            margin-left: 5px;
            margin-right: 15px;
            cursor: pointer;
        }
    }

    ._cc
    {
        display: flex;
        justify-content: center;

    }
}


._nncm
{
    width: 80px;
    position: absolute;
    bottom: 40px;
    z-index: 999;
    right: 20.5%;
}

._nncm img
{
    width: 100%;

}

._code
{
    width: 150px;
    height: 150px;
    margin: 0 auto;
    // padding: 12px 0;
    // margin-left: -2px;

    img
    {
        width: 100%;
        height: 100%;
    }
}


@include respondTo('phone'){
    ._wrap{
        height: auto;
    }

    ._auto{
        padding: 0 8px;
        width: 100%;
        ._cont{
            display: block;
            margin-top: 20px;
            width: 100%;
            ._logo{
                width: 100%;
                img{
                    width: 100%;
                }
            }
            ._r{
                display: block;
                ._share{
                    width: 100%;
                    height: auto;
                    border: none;
                    padding: 0;
                    margin-top: 10px;
                    justify-content: space-around;
                }
                ._rx{
                    margin-left: 0;
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    h4{
                        order: 2;
                    }
                    p{
                        margin-right: 20px;
                    }
                }
            }
        }

        ._bottom{
            margin-top: 10px;
            ._tt{
                display: block;
                ._address{
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }
            ._bb{
                display: block;
                margin: 8px 0;
                i{
                    display: block;
                }
                span{
                    margin: 8px 0;
                    display: inline-block;
                }
            }
            ._cc{
                margin: 20px 0;
            }
        }

        ._nncm{
            right: 20px;
        }
    }
}
</style>