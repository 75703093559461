export const listMenu = [
    {
        title: "资讯",
        id: "information",
        child: [
            {
                subTitle: "新闻资讯",
            },
            {
                subTitle: "通知公告",
            },

            {
                subTitle: "党建工作",
            },
        ]
    },
    {
        title: "参观",
        id: "visit",
        child: [
            {
                subTitle: "开放时间",
            },
            {
                subTitle: "参观预约",
            },
            {
                subTitle: "展厅分布",
            },
            {
                subTitle: "交通路线",
            },
            {
                subTitle: "讲解服务",
            },
            {
                subTitle: "游览须知",
            },
            {
                subTitle: "便民服务",
            },
        ]
    },
    {
        title: "展览",
        id: "exhibit",
        child: [
            {
                subTitle: "基本陈列",
            },
            {
                subTitle: "临时展览",
            },
            // {
            //     subTitle: "文物交流",
            // },

        ]
    },
    {
        title: "保管",
        id: "takeCare",
        child: [
            {
                subTitle: "馆藏珍品",
            },

            {
                subTitle: "征集",
            },
            // {
            //     subTitle: "藏品总目",
            // },

        ]
    },
    {
        title: "社教",
        id: "education",
        child: [
            {
                subTitle: "社教活动",
            },
            {
                subTitle: "实景党课",
            },
            {
                subTitle: "志愿者",
            },


        ]
    },
    {
        title: "学术",
        id: "study",
        child: [
            // {
            //     subTitle: "学术委员会",
            // },
            // {
            //     subTitle: "学术活动",
            // },
            // {
            //     subTitle: "学术成果",
            // },


        ]
    },
    {
        title: "游径",
        id: "",
        child:[]
        
    },
    {
        title: "文创",
        id: "industries",
        child: [
            // {
            //     subTitle: "洛八办文创",
            // },
        ]
    },
    {
        title: "视觉",
        id: "homeVideo",
        child: [
            // {
            //     subTitle: "洛八办文创",
            // },
        ]
    },
    {
        title: "关于",
        id: "about",
        child: [
            // {
            //     subTitle: "纪念馆概况",
            // },
            // {
            //     subTitle: "大事记",
            // },
            // {
            //     subTitle: "机构设置",
            // },

        ]
    }
]