import axios from 'axios'
import { BASE_API } from "./base_api"
// import { getToken } from '@/utils/auth'
import router from '../router/router'
import NProgress from "nprogress"
import 'nprogress/nprogress.css' //这个样式必须引入

import { Loading } from 'element-ui'

let loadingInstance
// 对axios进行简单的封装

const instance = axios.create({
  baseURL: BASE_API, //设置请求的默认接口
  timeout: 10000, //设置请求的超时时间
  headers: {
    'Content-Type': 'application/json;charset=utf8',
  },
});
//请求验证拦截器
instance.interceptors.request.use((config) => {
  // 获取token如果有则给所有的请求都添加，如果没有则不添加
  // let token = localStorage.getItem("ycToken")
  // if (token) {
  //   config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  NProgress.start()

  setTimeout(() => {
    NProgress.done(); // 几秒后自动关闭进度条
  }, 3000); // 3000毫秒后自动关闭

  return config
}, error => {
  NProgress.done()

  console.log(error)
})
// //设置响应拦截器
instance.interceptors.response.use((res) => {
  // res就是每次请求到的结果
  // if (res.data.success === false && res.data.code == 23011) {
  //   sessionStorage.removeItem("token")
  //   router.push({path:'/login'})
  //   alert("长时间未操作，请重新登录")
  //   // bus.$emit('removeUserInfo')
  // }
  NProgress.done()

  if (res.data.code == 401) {
    // alert("登陆失效,请重新登陆")
    // router.push({path:'/login'})

    // location.href = '/login';
    // console.log(this.$router)

    // localStorage.removeItem("ycToken")
    // location.href='/index'
    // location.reload()

  }
  return res.data
}, error => {
  console.log(error)
  NProgress.done()
})

export default instance