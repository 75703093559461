import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';  //用于隐藏某些视口下的元素
import directive from "./derective/vSidein"
import { IMG_URL } from '@/axios/base_api'

import 'core-js/stable'; 
import 'regenerator-runtime/runtime';
import store from "./store/index"
Vue.prototype.$IMG_URL = IMG_URL
Vue.config.productionTip = false;
Vue.use(ElementUI);
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import Pagination from "@/components/Pagination";
import mainFooter from '@/components/footer/footer'
Vue.component('Pagination', Pagination)
Vue.component('mainFooter', mainFooter)

import "./assets/font/font.css"
import "./assets/css/reset.css"

import 'swiper/css/swiper.min.css'
import { scrollToSection } from './utils/common'

import { scallHtmlImg } from './utils/scallImg'
Vue.prototype.$scrollToSection = scrollToSection
Vue.prototype.$scallHtmlImg = scallHtmlImg

Vue.prototype.$description = '八路军驻洛办事处纪念馆，是为了纪念八路军驻洛阳办事处而成立的一座革命纪念馆，是全国爱国主义教育示范基地。 被列入《全国红色旅游经典景区名录》'
Vue.prototype.$keywords = '八路军驻洛办事处纪念馆,洛八办纪念馆,八路军驻洛办事处,八路军驻洛办事处纪念馆官网'

// ElementUI.Dialog.props.lockScroll.default = false
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {

    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount("#app");
