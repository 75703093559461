<template>
  <el-drawer
    title=""
    :visible.sync="drawer"
    :direction="direction"
    size="100%"
    :with-header="false"
    :append-to-body="true"
    :modal-append-to-body="false"
  >
    <div class="_drawer">
      <div class="_close" @click="drawer = false">
        <img
          src="@/assets/image/home-img/close.png"
          alt="八路军驻洛办事处纪念馆-官方网站"
        />
      </div>

      <div class="_content">
        <div class="_logo" @click="goHome">
          <img
            src="@/assets/image/home-img/com_logo.png"
            alt="八路军驻洛办事处纪念馆-官方网站"
          />
        </div>

        <div class="_cont" v-if="seen">
          <div class="_li" v-for="(item, index) in listMenu" :key="index">
            <h4>{{ item.title }}</h4>
            <div class="_child">
              <p v-for="(v, i) in item.child" :key="i" @click="go(item, v)">
                {{ v.subTitle }}
              </p>
              <!-- <p>新闻资讯</p>
                            <p>党的建议</p> -->
            </div>
          </div>
        </div>

        <div class="phoneMenu" v-if="!seen">
          <div class="_li" v-for="(item, index) in listMenu" :key="index">
            <h4 @click="goRoute(item)">{{ item.title }}</h4>
            <div class="_child" v-if="item.child.length > 0">
              <p v-for="(v, i) in item.child" :key="i" @click="go(item, v)">
                {{ v.subTitle }}
              </p>
              <!-- <p>新闻资讯</p>
                            <p>党的建议</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { listMenu } from "./list";
export default {
  data() {
    return {
      drawer: false,
      direction: "ttb",
      listMenu: listMenu,
      seen: false,
    };
  },
  mounted() {
    this.menuSeen();
  },
  methods: {
    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    open() {
      this.drawer = true;
    },
    go(item, v) {
      // console.log(item);
      // console.log(v);
      this.drawer = false;
      this.$router.push({
        path: `/${item.id}`,
        query: {
          tit: v.subTitle,
        },
      });
    },

    goRoute(item) {
      console.log(item);
      this.drawer = false;
      setTimeout(() => {
        if (item.title == "游径") {
          window.open(this.$store.state.homeData.trails);
        } else {
          this.$router.push({
            path: `/${item.id}`,
          });
        }
      }, 50);
    },
    goHome() {
      this.drawer = false;
      this.$router.push({
        path: `/`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";
._drawer {
  width: 100%;
  height: 100%;
  // background: linear-gradient(180deg, #A63434 0%, #A63434 47%, #902121 100%);
  background-image: url("../../assets/image/home-img/menu_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  ._close {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  ._content {
    margin-top: 100px;

    ._logo {
      width: 100%;
      text-align: center;

      img {
        width: 300px;
      }
    }

    ._cont {
      width: 50%;
      margin: 0 auto;
      margin-top: 100px;
      display: flex;
      justify-content: space-between;

      ._li {
        text-align: center;

        h4 {
          color: white;
          font-weight: bold;
          font-size: 20px;
        }

        ._child {
          margin-top: 20px;

          p {
            color: #ecaaaa;
            font-size: 16px;
            line-height: 30px;
            cursor: pointer;
          }

          p:hover {
            color: white;
          }
        }
      }
    }
  }
}

@include respondTo("phone") {
  ._content {
    margin-top: 50px !important;
  }

  ._drawer {
    overflow: auto;
  }
  .phoneMenu {
    width: 100%;
    margin-top: 20px;
    ._li {
      margin-bottom: 15px;
      h4 {
        color: #eed798;
        font-size: 20px;
        margin-left: 20px;
      }
      ._child {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: #8c3123;
        padding: 10px 20px;

        p {
          color: #e1cf9e;
          font-size: 16px;
          cursor: pointer;
          margin-right: 4.5%;
          line-height: 30px;
        }
      }
    }
  }
}
</style>


