import axios from "@/axios/until";

/**
 * 查询首页数据
 */
export function getHomeData(data) {
  return axios({
    url: '/home',
    method: 'post',
    data: data
  })
}

/**
 * 网站数据
 */
export function getweb_data(data) {
  return axios({
    url: '/web_data',
    method: 'post',
    data: data
  })
}

/**
 * 资讯列表
 */

export function informationList(data) {
  return axios({
    url: '/informationList',
    method: 'post',
    data: data
  })
}
/**
 * 资讯详情
 */
export function informationDetail(data) {
  return axios({
    url: '/informationDetail',
    method: 'post',
    data: data
  })
}

/**
 * 参观
 */
export function getVisit(data) {
  return axios({
    url: '/visit',
    method: 'post',
    data: data
  })
}

/**
 * 基本陈列展厅列表
 */
export function exhibitionList(data) {
  return axios({
    url: '/exhibitionList',
    method: 'post',
    data: data
  })
}
/**
 * 基本陈列详情 exhibitionDetail
 */

export function exhibitionDetail(data) {
  return axios({
    url: '/exhibitionDetail',
    method: 'post',
    data: data
  })
}

/**
 * 临时展览 tempExhibit
 */
export function tempExhibit(data) {
  return axios({
    url: '/tempExhibit',
    method: 'post',
    data: data
  })
}

/**
 * 临时展览详情 tempExhibitDetail
 */

export function tempExhibitDetail(data) {
  return axios({
    url: '/tempExhibitDetail',
    method: 'post',
    data: data
  })
}

/**
 * 文物交流列表 collectionExchange
 */
export function collectionExchange(data) {
  return axios({
    url: '/collectionExchange',
    method: 'post',
    data: data
  })
}

/**
 * 文物交流详情 collectionExchangeDetail
 */
export function collectionExchangeDetail(data) {
  return axios({
    url: '/collectionExchangeDetail',
    method: 'post',
    data: data
  })
}

/**
 * 馆藏列表其他数据
 */
export function collectionListOther(data) {
  return axios({
    url: '/collectionListOther',
    method: 'post',
    data: data
  })
}

/**
 * 藏品类别列表
 */
export function collectionList(data) {
  return axios({
    url: '/collectionList',
    method: 'post',
    data: data
  })
}

/**
 * 藏品类别详情
 */
export function collectionDetail(data) {
  return axios({
    url: '/collectionDetail',
    method: 'post',
    data: data
  })
}

/**
 * 藏品征集
 */
export function collectionCollecting(data) {
  return axios({
    url: '/collectionCollecting',
    method: 'post',
    data: data
  })
}

/**
 * 藏品总目
 */
export function collectionHeadList(data) {
  return axios({
    url: '/collectionHeadList',
    method: 'post',
    data: data
  })
}
/**
 * 社教活动
 */
export function getSEA(data) {
  return axios({
    url: '/SEA',
    method: 'post',
    data: data
  })
}
/**
 * 社教活动详情
 */

export function SEADetail(data) {
  return axios({
    url: '/SEADetail',
    method: 'post',
    data: data
  })
}

/**
 * 实景活动详情
 */
export function partyClassDetail(data) {
  return axios({
    url: '/partyClassDetail',
    method: 'post',
    data: data
  })
}

/**
 * 实景党课
 */
export function partyClass(data) {
  return axios({
    url: '/partyClass',
    method: 'post',
    data: data
  })
}

/**
 * 志愿者 
 */
export function volunteer(data) {
  return axios({
    url: '/volunteer',
    method: 'post',
    data: data
  })
}

/**
 * 志愿者风采列表
 */
export function getAd(data) {
  return axios({
    url: '/vd',
    method: 'post',
    data: data
  })
}

/**
 * 学术委员会
 */
export function academicCommittee(data) {
  return axios({
    url: '/academicCommittee',
    method: 'post',
    data: data
  })
}


/**
 * 学术
 */
export function academic(data) {
  return axios({
    url: '/academic',
    method: 'post',
    data: data
  })
}
/*
学术详情
 */
export function academicDetail(data) {
  return axios({
    url: '/academicDetail',
    method: 'post',
    data: data
  })
}




/**
 * 学术活动 
 */

export function academicActivities(data) {
  return axios({
    url: '/academicActivities',
    method: 'post',
    data: data
  })
}

/**
 * 学术活动详情
 */

export function academicActivitiesDetail(data) {
  return axios({
    url: '/academicActivitiesDetail',
    method: 'post',
    data: data
  })
}

/**
 * 学术成果列表
 */

export function academicAchievements(data) {
  return axios({
    url: '/academicAchievements',
    method: 'post',
    data: data
  })
}

/**
 * 学术成果 详情
 */
export function academicAchievementsDetail(data) {
  return axios({
    url: '/academicAchievementsDetail',
    method: 'post',
    data: data
  })
}

/**
 * 文创列表
 */

export function culturalCreativity(data) {
  return axios({
    url: '/culturalCreativity',
    method: 'post',
    data: data
  })
}

/**
 * 关于
 */

export function about(data) {
  return axios({
    url: '/about',
    method: 'post',
    data: data
  })
}

/**
 * 大事记
 */
export function memorabilia(data) {
  return axios({
    url: '/memorabilia',
    method: 'post',
    data: data
  })
}

/**
 * 机构设置
 */
export function IS(data) {
  return axios({
    url: '/IS',
    method: 'post',
    data: data
  })
}


/**
 * 藏品征集 列表 collectionCollectingList
 */
export function collectionCollectingList(data) {
  return axios({
    url: '/collectionCollectingList',
    method: 'post',
    data: data
  })
}


export function hits(data) {
  return axios({
    url: '/hits',
    method: 'post',
    data: data
  })
}

/**
 * 顶部视频
 */


export function topVideo(data) {
  return axios({
    url: '/topVideo',
    method: 'post',
    data: data
  })
}

/**
 * 视频列表
 */
export function videoList(data) {
  return axios({
    url: '/videoList',
    method: 'post',
    data: data
  })
}

/**
 * 视频点击
 */

export function videoHits(data) {
  return axios({
    url: '/videoHits',
    method: 'post',
    data: data
  })
}


/**
 * 首页底部图
 */
export function getVr(data) {
  return axios({
    url: '/vr',
    method: 'post',
    data: data
  })
}






























