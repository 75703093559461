let BASE_API,IMG_URL
if (process.env.NODE_ENV === "development") {

    /**
     * 开发环境
     */

	BASE_API = "https://www.lybaban.com/api/v1"

    IMG_URL = "https://www.lybaban.com"

	
} else {


    /**
     * 生产环境
     */
    BASE_API = "https://www.lybaban.com/api/v1" //生产环境api
    IMG_URL = "https://www.lybaban.com"
    

}
export { BASE_API,IMG_URL}