<template>
  <div class="header" :style="styleHeader">
    <!-- <div class="_ceshi">测试用</div> -->
    <div class="_headerAuto">
      <div class="logo_img" :style="styleLogo">
        <div @click="logoClick">
          <div class="header-logo">
            <img
              id="header-img"
              src="@/assets/image/home-img/com_logo.png"
              alt="八路军驻洛办事处纪念馆-官方网站"
              @click="$router.push('/')"
            />
            <h1>八路军驻洛办事处纪念馆</h1>
          </div>
        </div>
        <!-- <div style="display:flex; float:left; height: 65px;" @click="logoClick">
                    <div class="header-important">
                        <div class="company_name">研究院</div>
                        <div class="header-title company_name">yanjiuyuan.com</div>
                    </div>
                </div> -->
      </div>

      <div class="_right" :style="styleRight">
        <!-- <img src="@/assets/image/home-img/en.png" alt="八路军驻洛办事处纪念馆-官方网站">
                <img src="@/assets/image/home-img/search.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
        <img
          src="@/assets/image/home-img/more.png"
          alt="八路军驻洛办事处纪念馆-官方网站"
          @click="goMore"
        />
      </div>

      <!-- <div class="logo_img"></div> -->
    </div>

    <div class="_headerMenu" :style="menuStyle">
      <div id="menu_index">
        <!-- web端菜单导航 -->
        <!-- <el-menu v-if="seen" router :default-active="activeIndex" class="el-menu-demo" menu-trigger="click"
                    text-color="rgb(204, 204, 204)" active-text-color="#fff" mode="horizontal" style="font-size: 20px;"
                    @select="handleSelect">
                    <el-menu-item v-for="(item, index) in menuList" :key="index" :index="item.id" class="header-title">{{
                        item.name }}
                    </el-menu-item>

                </el-menu> -->
        <div class="_webMenu" v-show="seen" @mouseleave="hoverLeave">
          <div class="_ww_menu" :style="wwStyle">
            <div
              :class="{ _li: true, _liHover: current == index }"
              v-for="(item, index) in menuList"
              :key="index"
              @mouseenter="hoverItem(item, index)"
              @click="goTO(item)"
            >
              {{ item.name }}
            </div>
          </div>

          <div class="_hidden" v-show="showHidden">
            <div class="_hidden_cont" :style="styleObj">
              <p
                v-for="(item, index) in subTitle"
                :key="index"
                @click="goTwo(item, index)"
              >
                <span>{{ item.subTitle }}</span>
                <img
                  v-if="subTitle.length - 1 != index"
                  src="@/assets/image/home-img/rhombus.png"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <menuDrawer ref="menuDrawerRef"></menuDrawer>
  </div>
</template>

<script>
import menuDrawer from "./menuDrawer.vue";
import { listMenu } from "./list";

export default {
  components: {
    menuDrawer,
  },
  data() {
    return {
      styleObj: {},
      styleLogo: {},
      styleRight: {},
      menuStyle: {},
      wwStyle: {},
      listMenu: listMenu,
      showHidden: false,
      current: -1,
      styleHeader: {},
      showTop: true,
      menuList: [
        {
          id: "/",
          name: "首页",
        },
        {
          id: "information",
          name: "资讯",
        },
        {
          id: "visit",
          name: "参观",
        },
        {
          id: "exhibit",
          name: "展览",
        },
        {
          id: "takeCare",
          name: "保管",
        },
        {
          id: "education",
          name: "社教",
        },
        {
          id: "study",
          name: "学术",
        },
        {
          id: "trail",
          name: "游径",
        },
        {
          id: "industries",
          name: "文创",
        },
        {
          id: "homeVideo",
          name: "视觉",
        },
        {
          id: "about",
          name: "关于",
        },
      ],
      activeIndex: "index",
      seen: true,
      currentMenu: "首页",
      subTitle: [
        // {
        //     subTitle: "通知公告",
        // },
        // {
        //     subTitle: "新闻资讯",
        // },
        // {
        //     subTitle: "党的建设",
        // },
      ],
    };
  },
  created() {
    if (localStorage.getItem("activeIndex")) {
      this.activeIndex = localStorage.getItem("activeIndex");
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.menuSeen();
  },
  methods: {
    handleScroll(e) {
      const distance = window.scrollY;
      // console.log(distance)
      if (distance > 400) {
        this.showTop = false;
        this.styleHeader = {
          height: "70px",
        };
        this.styleLogo = {
          position: "absolute",
          left: "20px",
          top: "8px",
          zIndex: 10,
        };
        this.styleRight = {
          position: "absolute",
          right: "20px",
          zIndex: 10,
        };
        this.menuStyle = {
          marginTop: "10px",
        };
        this.wwStyle = {
          width: "65%",
        };
      } else {
        this.showTop = true;
        this.styleHeader = {
          height: "140px",
        };
        this.wwStyle = {
          width: "70%",
        };
        this.menuStyle = {};
        this.styleLogo = {};
        this.styleRight = {};
      }
    },
    goTwo(item, index) {
      console.log(item);

      const title = item.subTitle;
      let path = "";
      for (let i in this.listMenu) {
        for (let j in this.listMenu[i].child) {
          if (title == this.listMenu[i].child[j].subTitle) {
            path = this.listMenu[i].id;
          }
        }
      }
      console.log(path);
      this.$router.push({
        path: path,
        query: {
          tit: item.subTitle,
        },
      });
    },
    goTO(item) {
      if (item.name == "游径") {
        window.open(this.$store.state.homeData.trails);
      } else {
        this.$router.push({
          path: item.id,
        });
      }
    },
    hoverItem(item, index) {
      var arr = ["首页", "游径", "学术", "关于", "文创", "视觉"];

      if (!arr.includes(item.name)) {
        this.current = index;
        this.showHidden = true;
        this.styleObj = {
          // transform: `translateX(${Number(index) * 140}px)`
          transform: `translateX(${Number(index) * 10}%)`,
        };
        for (let i in this.listMenu) {
          if (item.name == this.listMenu[i].title) {
            // console.log(this.listMenu[i])
            this.subTitle = this.listMenu[i].child;
          }
        }
      } else {
        this.current = -1;
        this.showHidden = false;
      }
    },
    hoverLeave() {
      this.current = -1;
      this.showHidden = false;
    },
    menuSeen() {
      // 屏幕尺寸
      let screenWidth = document.body.clientWidth;
      // console.log(screenWidth)
      if (screenWidth > 800) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    logoClick() {
      this.$router.push({
        name: "index",
      });
    },
    handleSelect(key) {
      console.log(key);
      localStorage.setItem("activeIndex", key);
    },
    handleSelectMobile(key) {
      console.log(key);
    },

    goMore() {
      this.$refs.menuDrawerRef.open();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../common/mixin.scss";

@media (max-width: 1441px) {
  ._headerAuto {
    width: 100% !important;
  }

  ._ww_menu {
    width: 90% !important;
  }
}

._webMenu {
  position: relative;
  height: 36px;
}

._webMenu ._ww_menu {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: white;
  width: 70%;
  margin: 0 auto;

  ._li {
    font-size: 20px;
    position: relative;
    cursor: pointer;
    height: 35px;
  }
}

._webMenu ._ww_menu ._liHover::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  right: 10px;
  border: 7px solid transparent;
  border-bottom: 8px solid rgba(119, 17, 17, 0.95);
  width: 0;
  height: 0;
  z-index: 1;
}

._hidden {
  position: absolute;
  bottom: -60px;
  color: white;
  width: 100%;
  height: 60px;
  background-color: rgba(109, 11, 11, 0.95);

  ._hidden_cont {
    display: flex;
    align-items: center;
    width: 75%;
    margin: 0 auto;

    p {
      line-height: 60px;

      span {
        cursor: pointer;
      }

      span:hover {
        color: #b41e1e;
      }

      img {
        width: 6px;
        height: 6px;
        margin: 0 10px;
      }
    }
  }
}

.logo_img {
  /* float:left; */
  // width: 30%;
  // flex: 1;
  cursor: pointer;
  // margin-right: 20px;
}

#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}

.header-title {
  font-size: 1em;
}

.company_name {
  font-weight: normal;
  font-size: 1em;
}

.footer_font {
  font-size: 1em;
}

#menu_index {
  //  float: left;
  // width: 50%;
  // height: 100%
  // margin: auto;
}

.header {
  width: 100%;
  height: 140px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(119, 17, 17, 0.85);
}

._headerAuto {
  margin: auto;
  width: 75%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 15px;

  ._right {
    img {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

._headerMenu {
  margin-top: 25px;
}

@media screen and (max-device-width: 415px) {
  .logo_img {
    float: left;
    width: auto;
    cursor: pointer;
    position: static !important;
  }

  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer;
  }

  .footer_font {
    font-size: 0.875em;
  }

  #menu_index {
    float: right;
    width: auto;
    height: 100%;
  }



  .header{
    height: 70px !important;
    
  }

  ._headerAuto{
    align-items: center;
    margin-top: 0;
    padding: 0 8px;
    height: 100%;
    
  }

  ._right{
    position: static !important;
  }
}

#header-img {
  width: 288px;
}

.header-logo {
  h1 {
    height: 0;
    opacity: 0;
  }
}

.header-important {
  float: left;
  margin-left: 6px;
  align-self: center;
}

.el-menu {
  background: unset;
}

.el-menu.el-menu--horizontal {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border: none;
}

.el-menu--horizontal > .el-menu-item {
  border: none;
  height: auto;
  line-height: normal;
}

._headerAuto {
  @include respondTo("iphone") {
    display: inherit;
  }
}

#menu_index {
  @include respondTo("iphone") {
    float: right;
  }
}








.el-menu--horizontal .el-menu-item {
  // font-size: 20px;
  padding: 0 40px;
}

::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: transparent !important;
}

::v-deep .el-menu--horizontal > .el-submenu:focus .el-submenu__title,
.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  background: transparent !important;
}

::v-deep .el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: #fff !important;
}
</style>