



export function scallHtmlImg() {

    const boxWidth = this.$refs.myHtml.offsetWidth
    console.log(boxWidth)


    //通过querySelectorAll选中所有的img标签
    var images = document.querySelectorAll('._html img');
    console.log(images); // 输出结果为包含所有图片的NodeList对象


    images.forEach((item, index) => {
        console.log(item)
        let imgWidth = item.width
        console.log(imgWidth)
        if (imgWidth < boxWidth) {
            console.log("xiaoyu")
            item.style.width = imgWidth
        } else {
            console.log("大于")
            item.style.width = boxWidth
        }


    })

    // for (let i in images) {

    // console.log(images[i])

    // let imgWidth = images[i].width
    // console.log(imgWidth)
    // if (imgWidth) {
    //     if (imgWidth < boxWidth) {
    //         images[i].style.width = 'auto'
    //     }  else if (imgWidth >= boxWidth) {
    //         images[i].style.width = boxWidth + "px"
    //     }
    // }




    // }








}