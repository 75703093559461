import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [

  {
    path: "/",
    name: "index",
    component: () => import("../views/index"),

  },
  {
    path: "/information",
    name: "information",

    component: () => import("../views/information/information.vue"),

  },
  {
    path: "/visit",
    name: "visit",

    component: () => import("../views/visit/visit.vue"),

  },
  {
    path: "/exhibit",
    name: "exhibit",

    component: () => import("../views/exhibit/exhibit.vue"),

  },
  {
    path: "/takeCare",
    name: "takeCare",

    component: () => import("../views/takeCare/takeCare.vue"),

  },
  {
    path: "/education",
    name: "education",

    component: () => import("../views/education/education.vue"),

  },
  {
    path: "/study",
    name: "study",

    component: () => import("../views/study/study.vue"),

  },
  {
    path: "/trail",
    name: "trail",

    component: () => import("../views/trail/trail.vue"),

  },
  {
    path: "/industries",
    name: "industries",

    component: () => import("../views/industries/industries.vue"),

  },

  {
    path: "/about",
    name: "about",
    component: () => import("@/views/about/about"),

  },
  {
    path: "/moreEdu",
    name: "moreEdu",
    component: () => import("@/views/education/moreEdu.vue"),

  },
  {
    path: "/informationDetail",
    name: "informationDetail",
    component: () => import("@/views/information/informationDetail.vue"),

  },
  {
    path: "/takeCareDetail",
    name: "takeCareDetail",
    component: () => import("@/views/takeCare/takeCareDetail.vue"),

  },
  {
    path: "/collectionDetail",
    name: "collectionDetail",
    component: () => import("@/views/takeCare/collectionDetail.vue"),

  },
  {
    path: "/exhibitDetail",
    name: "exhibitDetail",
    component: () => import("@/views/exhibit/exhibitDetail.vue"),

  },
  {
    path: "/tempExhibitDetail",
    name: "tempExhibitDetail",
    component: () => import("@/views/exhibit/tempExhibitDetail.vue"),

  },
  {
    path: "/collectionExchangeDetail",
    name: "collectionExchangeDetail",
    component: () => import("@/views/exhibit/collectionExchangeDetail.vue"),

  },
  {
    path: "/educationDetail",
    name: "educationDetail",
    component: () => import("@/views/education/educationDetail.vue"),

  },
  {
    path: "/studyDetail",
    name: "studyDetail",
    component: () => import("@/views/study/studyDetail.vue"),

  },
  {
    path: "/detail_pdf",
    name: "detail_pdf",
    component: () => import("@/views/study/detail_pdf.vue"),

  },
  {
    path: "/homeVideo",
    name: "homeVideo",
    component: () => import("@/views/video/homeVideo.vue"),

  },



];

const router = new VueRouter({
  mode: "history",
  // mode: "hash",

  routes,
});



router.beforeEach((to, from, next) => {
  const path = to.path;
  // 如果路径后面多了反斜杠，进行调整
  console.log(path)
  if (path.endsWith('/') && path != '/') {
    const newPath = path.slice(0, -1);
    // window.location.replace(newPath);
    next({ path: newPath })
    console.log(newPath)
  }

  next();
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


export default router;
