<template>
  <div>

    <navMenu></navMenu>


    <!-- <keep-alive> -->
    <router-view></router-view>
    <!-- </keep-alive> -->



  </div>
</template>

<script>
import navMenu from '../components/navMenu/navMenu.vue';
export default {
  components: {
    navMenu
  },
  data() {
    return {

    }
  },
  created() {

  }
};
</script>

<style></style>
